import {Step, StepType} from "./step";

export class Scenario {

  steps: Step[] = [];
  axles: number = 0;
  isRightDriver = false;

  isOneStep() {
    return new Set(this.steps.map(s => s.position)).size === 1;
  }

  isFirstStep(position: number) {
    return this.steps[0]?.position === position;
  }

  static ScenarioBuilder = class {

    private readonly scenario: Scenario;
    private readonly rightDriverCountries = ['gb', 'ie', 'mt', 'cy', 'in', 'jp', 'au', 'nz'];

    private licenseCountry?: string;
    private stepTypes: StepType[] = [];
    private onlyOneTyrePerAxle = false;

    constructor() {
      this.scenario = new Scenario();
    }

    setAxles(axles: number) {
      this.scenario.axles = axles;
      return this;
    }

    setStepTypes(stepTypes: StepType[]) {
      this.stepTypes = stepTypes;
      return this;
    }

    setOnlyOneTyrePerAxle(onlyOneTyrePerAxle: boolean) {
      this.onlyOneTyrePerAxle = onlyOneTyrePerAxle;
      return this;
    }

    setLicenseCountry(licenseCountry?: string) {
      this.licenseCountry = licenseCountry;
      return this;
    }

    /**
     * Car tyre rotation is
     * 1 --- 2
     * |     |
     * 4 --- 3
     */
    build() {
      const tyreNumber = this.scenario.axles * 2;
      for (let i = 0; i < this.scenario.axles; i++) {
        for (let step of this.stepTypes) {
          if (i === 0) {
            this.scenario.steps.push(new Step(i + 1, step));
            if (!this.onlyOneTyrePerAxle) {
              this.scenario.steps.push(new Step(i + 2, step));
            }
          } else {
            if (!this.onlyOneTyrePerAxle) {
              this.scenario.steps.push(new Step(i + 2, step));
            }
            this.scenario.steps.push(new Step(tyreNumber - i + 1, step));
          }
        }
      }

      // If there is more than 1 tyre per axle we need to group them together.
      if (!this.onlyOneTyrePerAxle) {
        if (this.isRightDriver()) {
          const order = [2, 1, 4, 3];

          this.scenario.steps = this.scenario.steps.sort((a, b) => {
            const positionA = order.indexOf(a.position);
            const positionB = order.indexOf(b.position);
            return positionA - positionB;
          });
        } else {
          this.scenario.steps = this.scenario.steps.sort((s1, s2) => s1.position - s2.position);
        }
      }
      this.scenario.isRightDriver = this.isRightDriver();
      return this.scenario;
    }

    private isRightDriver() {
      return !!(this.licenseCountry && this.rightDriverCountries.includes(this.licenseCountry));
    }
  }

  static CAR_SCENARIO(licenseCountry?: string){
    return new Scenario.ScenarioBuilder()
      .setLicenseCountry(licenseCountry)
      .setAxles(2)
      .setStepTypes(Object.values(StepType))
      .build();
  }
  static get TWO_TYRES_SCENARIO() {
    return new Scenario.ScenarioBuilder().setAxles(2).setStepTypes([StepType.REFERENCE]).setOnlyOneTyrePerAxle(true).build();
  }
  static get SINGLE_TYRE_SCENARIO() {
    return new Scenario.ScenarioBuilder().setAxles(1).setOnlyOneTyrePerAxle(true).setStepTypes([StepType.REFERENCE]).build();
  }

}
