import {Injectable} from '@angular/core';
import {Utils} from "../../utils/generics.utils";
import {ConfigService} from "../../../core/config";
import {BehaviorSubject, filter, switchMap} from "rxjs";
import {AuthService} from "../../../core/auth/auth.service";
import * as Sentry from "@sentry/angular-ivy";
import {StoreService} from "../../services/store.service";
import {Context} from "../../../core/models/context";
import {ReferenceApiService} from "../../../core/api/reference-api/reference-api.service";

@Injectable({
  providedIn: 'root'
})
export class TcuService {

  private readonly _dialogShown: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly dialogShown$ = this._dialogShown.asObservable();

  private readonly _tcuAccepted: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly tcuAccepted$ = this._tcuAccepted.asObservable();

  constructor(private referenceApi: ReferenceApiService,
              private config: ConfigService,
              private auth: AuthService,
              private store: StoreService
  ) {
    this.store.context$.pipe(filter((context) => context === Context.PRO),
      switchMap((_) => this.auth.connectedUser$),
      filter((user) => user?.userId != null),
      switchMap((user) => {
        const tcuVersion = this.config.getFeatures().ctuCurrentVersion;
        return user!.firstConnection ? this.setAcceptedTcu(tcuVersion) : this.getAcceptedTcu()
      })).subscribe({
        next: (result) => {
          if (result) {
            const tcuVersion = this.config.getFeatures().ctuCurrentVersion;
            // If last accepted answer is different from than current version show dialog.
            result.version != tcuVersion ? this.showTcuDialog() : this.hideTcuDialog();
          }
        },
        error: (err) => {
          if (err.status === 404) {
            this.showTcuDialog();
          } else {
            Sentry.captureException(new Error('Tcu error'), {extra: {err}});
          }
        }
    })
  }

  getAcceptedTcu() {
    return this.referenceApi.getAcceptedTcu(Utils.getCompanyFromDomain());
  }

  setAcceptedTcu(tcuVersion: number) {
    return this.referenceApi.setTcuForUser(Utils.getCompanyFromDomain(), tcuVersion);
  }

  showTcuDialog() {
    this._dialogShown.next(true);
  }

  hideTcuDialog() {
    this._dialogShown.next(false);
  }

  tcuAccepted() {
    this._tcuAccepted.next(true);
  }

  reset() {
    this._tcuAccepted.next(false);
  }

}
