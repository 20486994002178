import {inject, Injectable} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {Config, ConfigService} from "../../config";
import {LicenseResponseDto} from "../../dto/license-response-dto";
import {VehicleRequestDto} from "../../dto/vehicle-request-dto";
import {VehicleResponseDto} from "../../dto/vehicle-response-dto";
import {Context} from "../../models/context";
import {AnalysisResponseDto} from "../../dto/analysis-response-dto";
import {Utils} from "../../../shared/utils/generics.utils";
import {catchError, Observable, throwError} from "rxjs";
import {GetAnalysisResponseDto} from "../../dto/get-analysis-response-dto";
import {AnalysisStatusEnum} from "../../models/analysis-status";
import {UpdateResponseAnalysisDto} from "../../dto/update-response-analysis-dto";
import {TyreAnalysisRequestDto} from "../../dto/tyre-analysis-request-dto";
import {TyreAnalysisResponseDto} from "../../dto/tyre-analysis-response-dto";
import {RemindersRequestDto} from "../../dto/reminders-request-dto";
import {LastAnalysisResponseDto} from "../../dto/last-analysis-response-dto";
import {ReportResponseDto} from "../../dto/report-response-dto";
import {TcuResponse} from "../../models/tcu-response";
import {RecommendationsResponseDto} from "../../dto/recommendations-response-dto";
import {PredictionsResponseDto} from "../../dto/predictions-response-dto";
import {CompanyResponseDto} from "../../dto/company-response-dto";
import {MatomoResponseDto} from "../../dto/matomo-response-dto";
import {VehicleInfoResponseDto} from "../../dto/vehicle-info-response-dto";
import {AnalysisPostcode} from "../../models/analysis";
import {CbdDto} from "../../dto/cbd-dto";
import {PatchAnalysisResponseDto} from "../../dto/patch-analysis-response-dto";
import {PostContactResponseDto} from "../../dto/post-contact-response-dto";

@Injectable({
  providedIn: 'root'
})
export class ReferenceApiService {

  private http = inject(HttpClient);
  private configService = inject(ConfigService);
  private config = this.configService.getConfig().rool

  setConfig(config: Config) {
    this.config = config.rool;
  }

  ///////////////// Vehicle //////////////////

  getVehicleIdByLicensePlate(licensePlate: string) {
    return this.http.get<LicenseResponseDto>(
      `${this.config?.referenceApi.url}/licenses/${licensePlate}`
    );
  }

  createVehicle(vehicle: VehicleRequestDto) {
    return this.http.post<string>(
      `${this.config?.referenceApi.url}/vehicles`,
      vehicle, {observe: 'response'}
    )
  }

  getVehicleInfo(licensePlate: string, countryIso: string) {
    return this.http.get<VehicleInfoResponseDto>(`${this.config?.referenceApi.url}/vehicles/${licensePlate}/info?licenseCountry=${countryIso}`)
  }

  getVehicle(vehicleId: number) {
    return this.http.get<VehicleResponseDto>(`${this.config?.referenceApi.url}/vehicles/${vehicleId}`);
  }

  ///////////////// Contact //////////////////

  createContact(analysesId: number, email?: string, phone?: string) {
    return this.http.post<PostContactResponseDto>(
      `${this.config?.referenceApi.url}/analyses/${analysesId}/contacts`,
      {email, phone});
  }

  patchUser(userId: string, phone: string) {
    return this.http.patch<Response>(
      `${this.config?.referenceApi.url}/users/${userId}`,
      {phone}, { observe: 'response' });
  }

  patchOptin(consent: boolean, contactId: number, contextUUID: string) {
    return this.http.patch<Response>(`${this.config?.referenceApi.url}/contacts/${contactId}/opt-ins/${contextUUID}`, {consent}, {observe: 'response'});
  }

  ///////////////// Analysis //////////////////

  createAnalysis(vehicleId: number,context: Context, companyId: number, fleet: string, language: string, vehicleMileage?: number, externalId?: string) {
    return this.http.post<AnalysisResponseDto>(
      `${this.config?.referenceApi.url}/v3/vehicles/${vehicleId}/analyses`,
      {appOrigin: Utils.getAppOrigin(context),
        contextOrigin: fleet, language,
        vehicleMileage, companyId, externalId}
    )
  }

  getAnalysis(vehicleId: number, context: Context): Observable<GetAnalysisResponseDto> {
    return this.http.get<GetAnalysisResponseDto>(
      `${this.config?.referenceApi.url}/v3/vehicles/${vehicleId}/analyses?appOrigin=${Utils.getAppOrigin(context)}`);
  }

  updateAnalysis(analysisId: number, status?: AnalysisStatusEnum, vehicleMileage?: number, postcode?: AnalysisPostcode) {
    let body;
    if (postcode?.codeCommune &&  postcode?.codePostal) {
      body = {status, vehicleMileage, inseeCodeTravelZone: postcode?.codeCommune, zipCodeTravelZone: postcode?.codePostal}
    } else {
      body = {status, vehicleMileage}
    }
    return this.http.patch<UpdateResponseAnalysisDto>(
      `${this.config?.referenceApi.url}/v3/analyses/${analysisId}`,
      body, { observe: 'response' }
    )
  }

  createTyreAnalysis(analysisId: number, analysis: TyreAnalysisRequestDto) {
    return this.http.post<TyreAnalysisResponseDto>(
      `${this.config?.referenceApi.url}/v3/analyses/${analysisId}/tyre-analyses`,
      analysis
    )
  }

  updateTyreAnalysis(tyreAnalyseId: number, status: AnalysisStatusEnum) {
    return this.http.patch<Response>(
      `${this.config?.referenceApi.url}/v3/tyre-analyses/${tyreAnalyseId}`,
      {status}, { observe: 'response' }
    )
  }

  ///////////////// Reminder //////////////////

  createReminder(dto: RemindersRequestDto) {
    return this.http.post<Response>(`${this.config?.referenceApi.url}/reminders`, {...dto});
  }

  ///////////////// RECOMMENDATIONS //////////////////

  getRecommendations(analysisId: number) {
    return this.http.get<RecommendationsResponseDto>(`${this.config?.referenceApi.url}/v3/analyses/${analysisId}/recommendations`);
  }

  getPredictions(analysisId: number) {
    return this.http.get<PredictionsResponseDto>(`${this.config?.referenceApi.url}/v3/analyses/${analysisId}/predictions`)
  }

  ///////////////// COMPANIES //////////////////

  getCompany(company: string, language: string) {
    const params = new HttpParams().set("language", language);
    return this.http.get<CompanyResponseDto>(`${this.config?.referenceApi.url}/companies/${company}`, {params}).pipe(
      catchError((err) => {
        if (err.status === 404 && company.includes('-')) {
          const fallbackCompany = company.split('-')[0];
          return this.http.get<CompanyResponseDto>(`${this.config?.referenceApi.url}/companies/${fallbackCompany}`, {params})
        }
        return throwError(() => err);
      })
    );
  }

  ///////////////// MATOMO //////////////////

  getMatomoId(companyId: number) {
    return this.http.get<MatomoResponseDto>(`${this.config?.referenceApi.url}/companies/${companyId}/matomo-sites`, { observe: 'response' });
  }

  ///////////////// CBD //////////////////

  createCBDWear(tyreAnalysisId: number) {
    return this.http.post<CbdDto>(`${this.config?.referenceApi.url}/v3/tyre-analyses/${tyreAnalysisId}/cbd-wear`, {});
  }

  patchCBDWear(cbdWearId: number, status: AnalysisStatusEnum) {
    return this.http.patch<PatchAnalysisResponseDto>(`${this.config?.referenceApi.url}/cbd-wear/${cbdWearId}`, {status});
  }

  createCBDRef(tyreAnalysisId: number) {
    return this.http.post<CbdDto>(`${this.config?.referenceApi.url}/v3/tyre-analyses/${tyreAnalysisId}/cbd-reference`, {});
  }

  patchCBDRef(cbdRefId: number, status: AnalysisStatusEnum) {
    return this.http.patch<PatchAnalysisResponseDto>(`${this.config?.referenceApi.url}/cbd-reference/${cbdRefId}`, {status});
  }

  /*****************/
  /****** Pro ******/
  /*****************/


  ///////////////// Analysis //////////////////

  getLastAnalysis(vehicleId: number) {
    return this.http.get<LastAnalysisResponseDto>(`${this.config?.referenceApi.url}/v3/vehicles/${vehicleId}/analyses/last`);
  }

  getAnalysisReport(analysisId: number) {
    return this.http.get<ReportResponseDto>(`${this.config?.referenceApi.url}/v3/analyses/${analysisId}/report`);
  }

  ///////////////// TCU //////////////////

  getAcceptedTcu(company: string) {
    return this.http.get<TcuResponse>(`${this.config?.referenceApi.url}/tcu/${company}`);
  }

  setTcuForUser(company: string, version: number) {
    return this.http.post<TcuResponse>(`${this.config?.referenceApi.url}/tcu`, {company, version});
  }


  /******************/
  /****  Report  ****/
  /******************/

  getReportForVehicle(companyId: number, license: string) {
    return this.http.get<ReportResponseDto>(`${this.config?.referenceApi.url}/companies/${companyId}/licenses/${license}/reports/last`)
  }

  getReportFromContext(contactId: string, contextUuid: string) {
    return this.http.get<ReportResponseDto>(`${this.config?.referenceApi.url}/contacts/${contactId}/opt-ins/${contextUuid}/reports`);
  }
}
