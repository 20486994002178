import {HttpClient, HttpParams} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {Observable, shareReplay} from 'rxjs';
import {Config, ConfigService} from '../config';
import {MyVehicleResponseDto} from "../dto/my-vehicle-response-dto";
import {AppointmentResponseDto} from "../dto/appointment-response-dto";
import {AppointmentRequestResponseDto} from "../dto/appointment-request-response-dto";
import {ServiceResponseDto} from "../dto/service-response-dto";
import {AppointmentRequestRequestDto} from "../dto/appointement-request-request-dto";
import {CustomGarageResponseDto} from "../dto/custom-garage-response-dto";
import {AppointmentRequestStatus} from "../models/appointmentRequest";
import {AppointmentStatus} from "../models/appointment";
import {PostcodeResponseDto} from "../dto/postcode-response-dto";
import {FleetResponseDto} from "../dto/fleet-response-dto";
import {AssignVehiculeFleetResponse} from "../dto/assign-vehicule-fleet-response";
import {DriverProfilesRequestDto, DriverProfilesResponseDto} from "../dto/driver-profiles-dto";
import {TyreHotelsResponseDto} from "../dto/tyre-hotels-response-dto";
import {TyreHotelsRequestDto} from "../dto/tyre-hotels-request-dto";
import {CustomGarageRequestDto} from "../dto/custom-garage-request-dto";

@Injectable({
  providedIn: 'root',
})
export class ApiService {

  private http = inject(HttpClient);
  private configService = inject(ConfigService);
  private config = this.configService.getConfig().rool

  setConfig(config: Config) {
    this.config = config.rool;
  }

  uploadFile(file: File, url: string) {
    return this.http.put<Response>(url, file, {observe: 'response'});
  }

  ///////////////// Appointment //////////////////

  getLastAppointment(): Observable<AppointmentResponseDto> {
    return this.http.get<AppointmentResponseDto>(`${this.config?.appointmentApi.url}/appointments/last`)
  }

  getLastAppointmentRequest(): Observable<AppointmentRequestResponseDto> {
    return this.http.get<AppointmentRequestResponseDto>(`${this.config?.appointmentApi.url}/requests/last`)
  }

  createAppointmentRequest(dto: AppointmentRequestRequestDto) {
   return this.http.post<any>(`${this.config?.appointmentApi.url}/requests`, dto);
  }

  getServices() : Observable<ServiceResponseDto[]> {
    return this.http.get<ServiceResponseDto[]>(`${this.config?.appointmentApi.url}/services`);
  }

  getService(serviceId: number): Observable<ServiceResponseDto> {
    return this.http.get<ServiceResponseDto>(`${this.config?.appointmentApi.url}/services/${serviceId}`);
  }

  getCustomGarage(customGarageId: number) {
    return this.http.get<CustomGarageResponseDto>(`${this.config?.appointmentApi.url}/custom-garages/${customGarageId}`);
  }

  patchAppointmentRequest(appointmentRequestId: number, status: AppointmentRequestStatus) {
    return this.http.patch<Response>(`${this.config?.appointmentApi.url}/requests/${appointmentRequestId}`, {status});
  }

  patchAppointment(appointmentId: number, status: AppointmentStatus) {
    return this.http.patch<Response>(`${this.config?.appointmentApi.url}/appointments/${appointmentId}`, {status});
  }

  hasProfilPlusGaragesAround(latitude: number, longitude: number) {
    return this.http.post<Response>(`${this.config?.appointmentApi.url}/profilplus/garages/search`, {latitude, longitude});
  }


  ///////////////// FLEET //////////////////

  getFleet(fleet: string, companyId: number) {
    const params = new HttpParams().set("key", fleet);
    return this.http.get<FleetResponseDto>(`${this.config?.fleetApi.url}/companies/${companyId}/fleets`, {params});
  }

  getMyFleet(fleetId: string) {
    return this.http.get<Response>(`${this.config?.fleetApi.url}/fleets/${fleetId}/fleet-users/mine`);
  }

  // Only assign user as DEFAULT until we handle more role.
  assignUserToFleet(userId: number, fleetId: string)  {
    return this.http.post<Response>(`${this.config?.fleetApi.url}/fleets/${fleetId}/fleet-users`, {userId, role: 'DEFAULT'});
  }

  assignVehicleToFleet(vehicleId: number, fleetId: string) {
    return this.http.post<AssignVehiculeFleetResponse>(`${this.config?.fleetApi.url}/fleets/${fleetId}/fleet-vehicles`, {vehicleId});
  }

  assignVehicleToUser(userId: number, vehicleId: number, fleetId: string) {
    return this.http.post<Response>(`${this.config?.fleetApi.url}/fleets/${fleetId}/vehicle-assignments`, {vehicleId, userId});
  }

  assignAnalysisToFleet(fleetId: string, analysisId: number, userId: number) {
    return this.http.post<Response>(`${this.config?.fleetApi.url}/fleets/${fleetId}/analysis`, {analysisId, userId});
  }

  getMyFleetVehicle(fleetId: string) {
    return this.http.get<MyVehicleResponseDto>(`${this.config?.fleetApi.url}/fleets/${fleetId}/fleet-vehicles/mine`);
  }

  getTyreHotels(fleetId: string, vehicleId: number) {
    return this.http.get<TyreHotelsResponseDto>(`${this.config?.fleetApi.url}/fleets/${fleetId}/vehicles/${vehicleId}/tyre-hotels`);
  }

  createTyreHotels(fleetId: string, vehicleId: number, request: TyreHotelsRequestDto) {
    return this.http.post<TyreHotelsResponseDto>(`${this.config?.fleetApi.url}/fleets/${fleetId}/vehicles/${vehicleId}/tyre-hotels`, request);
  }

  updateTyreHotels(fleetId: string, vehicleId: number, request: TyreHotelsRequestDto) {
    return this.http.patch<TyreHotelsResponseDto>(`${this.config?.fleetApi.url}/fleets/${fleetId}/vehicles/${vehicleId}/tyre-hotels`, request);
  }

  ///////////////// USERS //////////////////

  getMyDriverProfile(fleetId: string) {
    return this.http.get<DriverProfilesResponseDto>(`${this.config?.fleetApi.url}/fleets/${fleetId}/driver-profiles/mine`);
  }

  createDriverProfile(fleetId: string, profile: DriverProfilesRequestDto) {
    return this.http.post<DriverProfilesResponseDto>(`${this.config?.fleetApi.url}/fleets/${fleetId}/driver-profiles/mine`, {...profile});
  }

  updateDriverProfile(fleetId: string, profile: DriverProfilesRequestDto) {
    return this.http.patch<DriverProfilesResponseDto>(`${this.config?.fleetApi.url}/fleets/${fleetId}/driver-profiles/mine`, {...profile});
  }

  ///////////////// GARAGES //////////////////

  createCustomGarage(garage: CustomGarageRequestDto) {
    return this.http.post<string>(`${this.config?.appointmentApi.url}/custom-garages`, garage, {observe: 'response'});
  }


  ///////////////// api.gouv.fr //////////////////

  getPostcode(postcode: string) {
    return this.http.get<PostcodeResponseDto[]>(`https://apicarto.ign.fr/api/codes-postaux/communes/${postcode}`);
  }

}
