import {Injectable} from '@angular/core';
import {SentryErrorHandler} from "@sentry/angular-ivy";

@Injectable()
export class GlobalErrorHandler extends SentryErrorHandler {

  constructor() {
    super({showDialog: false});
  }

  override handleError(error: any): void {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;

    if (chunkFailedMessage.test(error.message)) {
      window.location.reload();
      return;
    }

    return super.handleError(error);
  }

}
